import clsx from "clsx"
import { motion, useScroll, useTransform } from "framer-motion"
import { CheckIcon, FileQuestionIcon, FileWarningIcon } from "lucide-react"
import { GetServerSideProps } from "next"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Head from "next/head"
import { useRouter } from "next/router"
import { useEffect, useRef } from "react"
import askCodeVideo from "../components/landing/ask-code.mp4"
import { Container } from "../components/landing/components/Container"
import { HeroContainer } from "../components/landing/components/HeroContainer"
import { Footer } from "../components/landing/Footer"
import gitDiffVideo from "../components/landing/git-diff.mp4"
import { Header } from "../components/landing/Header"
import notifyVideo from "../components/landing/notify.mp4"
import { Button } from "../components/ui/button"
import useAuth from "../hooks/useAuth"

const Plan = ({
  name,
  price,
  description,
  href,
  features,
  featured = false
}: {
  name: string
  price: string
  description: string
  href: string
  features: string[]
  featured?: boolean
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <section
      className={clsx(
        "flex flex-col rounded-3xl px-6 sm:px-8",
        featured ? "order-first bg-gray-600 py-8 lg:order-none" : "lg:py-8"
      )}
    >
      <h3 className="font-display mt-5 text-lg text-white">{name}</h3>
      <p
        className={clsx(
          "mt-2 text-base",
          featured ? "text-white" : "text-slate-400"
        )}
      >
        {description}
      </p>
      <p className="font-display order-first text-5xl font-light tracking-tight text-white">
        {price}
      </p>
      <ul
        role="list"
        className={clsx(
          "order-last mt-10 flex flex-col gap-y-3 text-sm",
          featured ? "text-white" : "text-slate-200"
        )}
      >
        {features.map((feature) => (
          <li key={feature} className="flex">
            <CheckIcon
              className={featured ? "text-green-400" : "text-green-400"}
            />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>
      <Button
        onClick={() => {
          router.push(href)
        }}
        variant={"solidWhite"}
        className="mt-8"
        aria-label={`Get started with the ${name} plan for ${price}`}
      >
        {t("Get started")}
      </Button>
    </section>
  )
}

export default function Home() {
  const { t } = useTranslation(undefined, { useSuspense: true })
  const router = useRouter()
  const { user } = useAuth()
  const { scrollYProgress } = useScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0])

  const problemRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible")
            entry.target.classList.remove("not-visible")
          } else {
            entry.target.classList.remove("visible")
            entry.target.classList.add("not-visible")
          }
        })
      },
      { threshold: 0.1 }
    )

    problemRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      if (problemRefs.current) {
        problemRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref)
          }
        })
      }
    }
  }, [])

  const featureRefs = useRef<(HTMLDivElement | null)[]>([])

  const premiumFeatures = [
    {
      icon: "✏️",
      title: t("Cloud Editor"),
      description: t("Edit specs with our intuitive cloud-based editor")
    },
    {
      icon: "🔄",
      title: t("Versioning Specs"),
      description: t("Track all changes with built-in versioning")
    },
    {
      icon: "👥",
      title: t("Collaboration"),
      description: t("Work together in real-time with your team")
    },
    {
      icon: "🔗",
      title: t("Repository Integration"),
      description: t("Connect seamlessly with existing repositories")
    },
    {
      icon: "🔔",
      title: t("Real-time Notifications"),
      description: t("Get instant alerts on code changes affecting specs")
    },
    {
      icon: "🔍",
      title: t("Git Diff Analysis"),
      description: t("Understand code changes without technical expertise")
    },
    {
      icon: "🔬",
      title: t("Spec-Code Comparison"),
      description: t("Identify discrepancies between specs and implementation")
    },
    {
      icon: "💬",
      title: t("Codebase Queries"),
      description: t("Ask questions about your codebase in natural language")
    },
    {
      icon: "🧠",
      title: t("AI-powered Analysis"),
      description: t("Get intelligent insights for your specifications")
    }
  ]

  useEffect(() => {
    const handleResize = () => {}

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible")
            entry.target.classList.remove("not-visible")
          } else {
            entry.target.classList.remove("visible")
            entry.target.classList.add("not-visible")
          }
        })
      },
      { threshold: 0.1 }
    )

    featureRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      if (featureRefs.current) {
        featureRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref)
          }
        })
      }
    }
  }, [])

  const pageTitle = "GenieSpec"
  const pageDescription = "GenieSpec is AI software specification editor"

  // Add state and functions for pricing

  // Update FAQ data
  const faqs = [
    {
      question: t("Is our data used for AI learning?"),
      answer: t(
        "No, it is not used. The AI in GenieSpec uses a paid API service that does not use customer data for learning purposes."
      )
    },
    {
      question: t("Do I need a GitHub account to use GenieSpec?"),
      answer: t(
        "Yes, a GitHub account is required to use GenieSpec. Our platform integrates closely with GitHub to provide real-time updates and analysis of your codebase."
      )
    },
    {
      question: t("Is there a GitHub app I need to install?"),
      answer: t(
        "Yes, you'll need to install the GenieSpec GitHub app to your repositories. This app allows GenieSpec to access your code changes and provide real-time notifications and analysis."
      )
    }
  ]

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://geniespec.com" />

        {/* Open Graph Tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content={pageImage} /> */}
        <meta property="og:url" content="https://geniespec.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:image" content={pageImage} /> */}
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        {/* Additional meta tags as needed */}
      </Head>
      <Header loggedIn={!!user.id} />

      <main className="text-white bg-gradient-to-b from-gray-950 to-gray-900">
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px]" />
          <motion.div
            style={{ opacity }}
            className="relative flex justify-center text-slate-50 min-h-screen"
          >
            <HeroContainer className="relative pt-32 pb-40 text-center">
              <motion.h5
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="pl-3 pb-2 relative font-display mx-auto max-w-5xl text-6xl font-bold tracking-tight sm:text-7xl lg:text-8xl bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600 to-pink-500 animate-text-shimmer"
              >
                {t("Never Outdated Software Spec Again")}
              </motion.h5>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="pl-3 relative mt-8 max-w-3xl text-center mx-auto text-xl lg:text-2xl tracking-tight text-gray-300"
              >
                {t(
                  "GenieSpec: Where AI meets Project Management. Keep your code and specs in perfect harmony."
                )}
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="mt-16 flex justify-center space-x-6"
              >
                <Button
                  variant="solidBlue"
                  onClick={() => router.push("/auth/sign-up")}
                >
                  {t("Start Free Trial")}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => router.push("#features")}
                >
                  {t("Learn More")}
                </Button>
              </motion.div>
            </HeroContainer>
          </motion.div>
        </div>

        {/* Problems Section */}
        <section className="py-20 bg-gradient-to-b from-gray-900 to-gray-950">
          <Container>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl font-bold mb-4 text-gray-50">
                {t("Common Challenges in Spec Management")}
              </h2>
              <p className="text-xl text-gray-300 max-w-2xl mx-auto">
                {t("Are you facing these critical issues?")}
              </p>
            </motion.div>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-8 max-w-6xl mx-auto">
              {[
                {
                  icon: (
                    <FileQuestionIcon className="w-12 h-12 text-blue-400" />
                  ),
                  title: t("Uncertain Current Spec State"),
                  description: t(
                    "Difficulty in determining the current status of specifications - whether they are up-to-date, partially implemented, or not yet started."
                  )
                },
                {
                  icon: (
                    <FileWarningIcon className="w-12 h-12 text-yellow-400" />
                  ),
                  title: t("Outdated Specifications"),
                  description: t(
                    "Specs frequently become outdated as the project evolves, leading to misalignment between documentation and actual implementation."
                  )
                }
              ].map((problem, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2"
                >
                  <div className="flex justify-center mb-6">
                    <div className="p-3 bg-gray-700 rounded-full">
                      {problem.icon}
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-gray-200 text-center">
                    {problem.title}
                  </h3>
                  <p className="text-gray-300 text-center">
                    {problem.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </Container>
        </section>

        {/* Primary Features Section */}
        <section id="features" className="py-20 bg-gray-950">
          <Container>
            <h2 className="font-display text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl md:text-5xl text-center mb-8">
              {t("GenieSpec: Your AI-Powered Spec Management Solution")}
            </h2>
            <p className="text-xl text-gray-300 text-center max-w-3xl mx-auto mb-16">
              {t(
                "The most trustworthy source of truth is the code itself. With GenieSpec, you can seamlessly integrate code and specifications, ensuring your specs are always up-to-date and in perfect harmony with your codebase."
              )}
            </p>
            <div className="space-y-24">
              {[
                {
                  title: t("Real-time Notification when code changes"),
                  description: t(
                    "Stay informed about changes in your codebase and specifications in real-time, ensuring perfect alignment between documentation and implementation."
                  ),
                  icon: "🔔",
                  video: notifyVideo
                },
                {
                  title: t("AI-Driven Git Diff Analysis"),
                  description: t(
                    "Our AI analyzes git diffs to provide clear insights into code changes, making it easy for both technical and non-technical team members to understand updates."
                  ),
                  icon: "🔍",
                  video: gitDiffVideo
                },
                {
                  title: t("Ask Your Codebase"),
                  description: t(
                    "Leverage AI to query your codebase directly, bridging the gap between specifications and implementation for more accurate and up-to-date documentation."
                  ),
                  icon: "💬",
                  video: askCodeVideo
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  className="flex flex-col items-center bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl overflow-hidden shadow-2xl hover:shadow-3xl transition-shadow duration-300"
                >
                  <div className="w-full p-8 text-center">
                    <div className="flex items-center justify-center mb-4">
                      <span className="text-4xl">{feature.icon}</span>
                      <h3 className="font-display text-2xl font-bold text-gray-50 ml-4">
                        {feature.title}
                      </h3>
                    </div>
                    <p className="text-lg text-gray-300 max-w-2xl mx-auto mb-8">
                      {feature.description}
                    </p>
                  </div>
                  <div className="w-full px-6 pb-8">
                    <div className="max-w-4xl mx-auto bg-gray-700 rounded-lg overflow-hidden shadow-xl transform transition-all hover:scale-105 duration-300">
                      <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="w-full h-full object-cover"
                      >
                        <source src={feature.video} type="video/mp4" />
                        {t("Your browser does not support the video tag.")}
                      </video>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </Container>
        </section>

        {/* Premium Features Section */}
        <section className="py-20 bg-gradient-to-b from-gray-900 to-gray-950">
          <Container>
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold mb-4 text-gray-200">
                {t("All Premium Features")}
              </h2>
              <p className="text-xl text-gray-50 max-w-2xl mx-auto">
                {t(
                  "Unlock the full potential of GenieSpec with our premium features"
                )}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {premiumFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="flex items-center mb-4">
                    <span className="text-3xl mr-3">{feature.icon}</span>
                    <h3 className="text-xl font-semibold text-gray-200">
                      {feature.title}
                    </h3>
                  </div>
                  <p className="text-gray-50">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </Container>
        </section>

        {/* Pricing Section */}
        <section
          id="pricing"
          aria-label="Pricing"
          className="py-20 bg-gradient-to-t from-gray-950 to-gray-900"
        >
          <Container>
            <div className="md:text-center">
              <h2 className="font-display text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">
                {t("Straightforward pricing, for every team.")}
              </h2>
              <p className="mt-4 text-xl text-slate-400">
                {t("Unlock the full potential of your team with GenieSpec.")}
              </p>
            </div>
            <div className="mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:max-w-none lg:grid-cols-3 xl:gap-x-8">
              <div></div>
              <Plan
                name={t("Team")}
                price={t("$15 per user / month")}
                href="/auth/sign-up"
                description={t(
                  "Kickstart with a 7-day free trial. No credit card needed."
                )}
                features={premiumFeatures.map((feature) => feature.title)}
              />
              <div></div>
            </div>
          </Container>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="py-20 bg-gray-950">
          <Container>
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-50">
              {t("Frequently Asked Questions")}
            </h2>
            <div className="max-w-3xl mx-auto space-y-8">
              {faqs.map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <h3 className="text-xl font-semibold mb-2 text-gray-50">
                    {faq.question}
                  </h3>
                  <p className="text-gray-300">{faq.answer}</p>
                </motion.div>
              ))}
            </div>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", ["common", "header"]))
    }
  }
}
