import {
  AuthError,
  AuthTokenResponsePassword,
  Session,
  User
} from "@supabase/supabase-js"
import { createClient } from "../utils/supabase/component"

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export type AuthUser = null | Record<string, any>

export type AuthState = {
  isAuthenticated: boolean
  isInitialized: boolean
  user: AuthUser
}

export type SupabaseAuthContextType = {
  loading: boolean
  session: Session | null
  user: User & { name: string | null }
  signInWithEmail: (
    email: string,
    password: string
  ) => Promise<AuthTokenResponsePassword>
  signUpWithEmail: (
    email: string,
    username: string,
    password: string
  ) => Promise<{ user: User | null; error: any }>
  // signInWithGoogle: () => Promise<UserCredential>
  signOut: () => Promise<void>
  resetPassword: (
    email: string
  ) => Promise<{ data: object | null; error: AuthError | null }>
  refetchUserInfo: () => void
}

export const dummyAuthContext: SupabaseAuthContextType = {
  loading: false,
  session: null,
  user: {
    id: "user",
    name: "name",
    email: "test",
    role: "user"
  } as User & { name: string },
  signInWithEmail: (email, password) =>
    createClient().auth.signInWithPassword({ email, password }),
  signUpWithEmail: () => {
    return Promise.resolve({ user: null, error: null })
  },
  // signInWithGoogle: () => {
  //   const auth = getAuth()
  //   const provider = new GoogleAuthProvider()
  //   return signInWithPopup(auth, provider)
  // },
  refetchUserInfo: () => {
    return
  },
  signOut: () => {
    return Promise.resolve()
  },
  resetPassword: () => {
    return Promise.resolve({ data: null, error: null })
  }
}
